/*
  noaccountbet.se
  noaccountcasino.se
  noaccountbet.ee
*/

:root {
  --app-color-brand: #e0b437;
  --app-color-brand-highlight: #eaec42;
  --app-color-brand-dark: #e0b437;
  --app-color-brand-light: #e0b437;

  --app-color-brand-accent: #1abfc6;
  --app-color-brand-accent-highlight: #2fdce4;
  --app-color-brand-accent-dark: #142127;
  --app-color-brand-accent-light: #1c2f38;

  --app-color-off-white: #fff;

  --app-background: var(--app-color-brand-accent-light);

  --app-header-height: 5em;
  --app-header-background: linear-gradient(
    222deg,
    var(--app-color-brand-accent-dark),
    var(--app-color-brand-accent-light)
  );

  --app-color-neutral-white: rgba(0, 0, 0, 0.5);
  --app-color-neutral: #fff;

  --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
  --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);
  --app-shadow-inset-low: inset 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-inset-mid: inset 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-inset-high: inset 0 5px 10px var(--app-color-shadow-line);
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src: url("../fonts/oswald/oswald-700.woff2") format("woff2"),
    url("../fonts/oswald/oswald-700.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

body {
  background: var(--app-background);
}

body,
input,
textarea,
select,
button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: var(--app-color-off-white);
}

h1,
h2,
h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  letter-spacing: -0.03em;
  line-height: 1.1;
  color: var(--app-color-brand) !important;
  text-transform: uppercase;
}

.app-logo::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  width: 400px;
  height: 100%;
  transform: translateX(-50%);
  opacity: 0.8;
  background: radial-gradient(
    at 50% -40%,
    rgb(47, 167, 221) 0%,
    transparent 70%
  );
  filter: blur(25px);
  z-index: -1;
}

section a {
  color: var(--app-color-brand) !important;
}

.app-locale-selector button svg {
  fill: var(--app-color-off-white);
}
