@import "../.resources/styles/na.css";
@import '../.resources/styles/footer-layout.css';

:root {
  --app-footer-background: var(--app-color-brand-accent-dark);
  --app-footer-text: var(--app-color-off-white);
}

body {
  background: linear-gradient(transparent, rgb(28, 47, 56)) 0% 0% / 100%,
    url("../.resources/backgrounds/golden.jpg") center top no-repeat
      rgb(28, 47, 56);
}

.app-logo::after {
  content: url("../.resources/svgs/logo-goldenbull.svg");
  width: 4em;
  margin-top: 0.3em;
}
